
import { Options, Vue } from 'vue-class-component'
import NaviBar from '@/components/NaviBar.vue'
import { Dialog } from 'vant'

@Options({
  components: {
    NaviBar
  }
})
export default class PracticeEvaluate extends Vue {
  currentTitleIndex = 0
  currentIndex = -1
  titleArr = ['你觉得这次练习会让自己更平静吗？', '你觉得这次练习会让自己更平静?????？', '你觉得这次练习会让自己更平静诶诶额？', '你觉得这次练习会让自己更平静吗？~~~']

  tapRating (index: number) {
    this.currentIndex = index
  }

  tapNext () {
    if (this.currentIndex < 0) {
      return
    }
    this.currentTitleIndex += 1
    this.currentIndex = -1
  }

  tapSkip () {
    // 跳过
  }

  goBack () {
    Dialog.confirm({
      title: '是否确定？',
      message: '确认后将退出练习评价',
      confirmButtonText: '退出评价'
    })
      .then(() => {
        // on confirm
        this.$router.go(-1)
      })
      .catch(() => {
        // on cancel
      })
  }
}
